import environmentShops from '../assets/shop-env.json'
import {environment} from "../global/app";
import {IEnv} from "../interfaces/env";

class EnvironmentServiceController {
  private environment: IEnv = environment;


  // get environment with url overwrites
  public getEnvironment(): IEnv {
    return this.environment;
  }

  // update environment with url overwrites
  updateFromURL(url:string) {
    let shop: { url: string[]; config: IEnv };
    for (shop of environmentShops) {
      for (const shopUrl of shop.url) {
        if (url.includes(shopUrl)) {
          if (shop.config) {
            Object.assign(this.environment, shop.config);
          }
        }
      }
    }
  }
}

export const EnvironmentService = new EnvironmentServiceController();
